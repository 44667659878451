//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { alertTriangleIcon, checkCircleIcon } from "@debionetwork/ui-icons";
import MenstrualCalendarBanner from "./Banner";
import { mapState } from "vuex";
import { getMenstrualSubscriptionPrices, getActiveSubscriptionByOwner } from "@/common/lib/polkadot-provider/query/menstrual-subscription";
import { getLastMenstrualCalendarByOwner } from "@/common/lib/polkadot-provider/query/menstrual-calendar";
import { addMenstrualSubscriptionFee, addMenstrualSubscription, setMenstrualSubscriptionPaid } from "@/common/lib/polkadot-provider/command/menstrual-subscription";
import { formatPrice } from "@/common/lib/price-format";
import { generalDebounce } from "@/common/lib/utils";
import { getConversion } from "@/common/lib/api";
import Web3 from "web3";
export default {
  name: "MenstrualCalendar",
  data: () => ({
    alertTriangleIcon,
    checkCircleIcon,
    plans: [{
      label: "Monthly",
      duration: "Monthly",
      description: "For users on a budget who want to try out menstrual calendar",
      price: 0,
      currency: "DBIO",
      usd: 0,
      promo: "",
      periode: "Month",
      promoPrice: 0
    }, {
      label: "Quarterly",
      duration: "Quarterly",
      description: "Get full benefits at a discounted price",
      price: 0,
      currency: "DBIO",
      usd: 0,
      promo: "",
      periode: "3 Months",
      promoPrice: 0
    }, {
      label: "Annually",
      duration: "Yearly",
      description: "Get full benefits at a discounted price",
      price: 0,
      currency: "DBIO",
      usd: 0,
      promo: "",
      periode: "Year",
      promoPrice: 0
    }],
    subscription: null,
    paymentPreview: false,
    isSuccess: false,
    showAlert: false,
    txWeight: 0,
    breadcrumbs: [{
      text: "Subscription Plan",
      disabled: false,
      href: ".menstrual-calendar__subscription-plan"
    }, {
      text: "Payment Preview",
      disabled: true,
      href: ".menstrual-calendar__plan-card"
    }],
    currency: "",
    loading: false,
    error: null,
    lowBalance: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      lastEventData: state => state.substrate.lastEventData,
      walletBalance: state => state.substrate.walletBalance
    })
  },
  watch: {
    lastEventData(e) {
      if (e !== null) {
        const dataEvent = JSON.parse(e.data.toString());
        if (dataEvent[1] === this.wallet.address) {
          if (e.method === "MenstrualSubscriptionAdded") {
            this.toPayment(dataEvent[0].id);
          }
          if (e.method === "MenstrualSubscriptionPaid") {
            this.showAlert = false;
            this.isSuccess = true;
            this.loading = false;
          }
        }
      }
    },
    subscription: {
      deep: true,
      immediate: true,
      handler: generalDebounce(async function () {
        await this.getTxWeight();
      }, 500)
    }
  },
  async created() {
    await this.getSubscriptionPrices();
    await this.getTxWeight();
    await this.getActiveSubscription();
  },
  async mounted() {
    await this.getActiveSubscription();
  },
  components: {
    MenstrualCalendarBanner
  },
  methods: {
    async getRate() {
      const rate = await getConversion();
      return rate.dbioToUsd;
    },
    getExpiredDate(period) {
      const today = new Date();
      let newDate;
      if (period === "Month") {
        newDate = new Date(today.setMonth(today.getMonth() + 1));
      }
      if (period === "3 Months") {
        newDate = new Date(today.setMonth(today.getMonth() + 3));
      }
      if (period === "Year") {
        newDate = new Date(today.setMonth(today.getMonth() + 12));
      }
      let day = newDate.getDate() - 1;
      let month = newDate.toLocaleString("default", {
        month: "short"
      });
      let year = newDate.getFullYear();
      return "".concat(day, " ").concat(month, " ").concat(year);
    },
    async toSusbsribe() {
      this.loading = true;
      const price = Number(String(this.subscription.price).split(",").join(""));
      if (this.walletBalance < price) {
        this.error = {
          title: "Insufficient Balance",
          message: "Your transaction cannot go through because your account balance is too low or doesn't meet the minimum deposit needed. Please check your balance."
        };
        this.showAlert = false;
        this.loading = false;
        return;
      }
      await addMenstrualSubscription(this.api, this.wallet, this.subscription.duration, this.subscription.currency);
    },
    async toPayment(id) {
      await setMenstrualSubscriptionPaid(this.api, this.wallet, id);
    },
    async getActiveSubscription() {
      const activeSubs = await getActiveSubscriptionByOwner(this.api, this.wallet.address);
      const menstrualCalendar = await getLastMenstrualCalendarByOwner(this.api, this.wallet.address);
      if (activeSubs) {
        if (!menstrualCalendar) {
          this.$router.push({
            name: "menstrual-calendar-selection"
          });
          return;
        }
        this.$router.push({
          name: "menstrual-calendar-detail"
        });
      }
    },
    async getSubscriptionPrices() {
      let monthlyPrice;
      this.plans.forEach(async plan => {
        getMenstrualSubscriptionPrices(this.api, plan.duration, plan.currency).then(data => {
          this.getRate().then(rate => {
            plan.price = formatPrice(data.amount, plan.currency);
            plan.usd = (Number(plan.price.split(",").join("")) * rate).toFixed(8);
            if (plan.duration === "Monthly") {
              monthlyPrice = plan.price;
            }
            if (plan.duration === "Quarterly") {
              plan.promoPrice = Number(monthlyPrice.split(",").join("")) * 3;
            }
            if (plan.duration === "Yearly") {
              plan.promoPrice = Number(monthlyPrice.split(",").join("")) * 12;
            }
          });
        });
      });
    },
    async getTxWeight() {
      const txWeight = await addMenstrualSubscriptionFee(this.api, this.wallet, this.subscription.duration, this.subscription.currency);
      this.txWeight = "".concat(Number(Web3.utils.fromWei(String(txWeight.partialFee), "ether")).toFixed(8), " DBIO");
    },
    setActive(currency) {
      return currency === this.subscription.currency ? "secondary" : "";
    },
    toPaymentPreview() {
      this.paymentPreview = true;
      this.breadcrumbs[0].disabled = true;
      this.breadcrumbs[1].disabled = false;
    },
    toSubsPlan() {
      this.paymentPreview = false;
      this.breadcrumbs[0].disabled = false;
      this.breadcrumbs[1].disabled = true;
    },
    toMenstrualCalendar() {
      this.showAlert = false;
      this.isSuccess = false;
      this.$router.push({
        name: "menstrual-calendar-selection"
      });
    }
  }
};